<template>
  <div class="done">
    <mt-header :title="$t('projectName')">
      <router-link to="/" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <ul>
        <li v-for="(item, index) in contractList" :key="index">
          <div class="box">
            <div class="box-title">
              <img :src="item.iconUrl" alt=""/>
              <span>{{ item.productName }}</span>
            </div>
            <ul class="item-ul" >
              <li class="item-li" v-for="(contract, key) in item.contractDetails" :key="key" @click="$router.push({ path: '/apply/orderDetails', query: { contractNo: contract.applyId } })">
                <div class="wrap">
                  <div class="row border-b">
                    <div class="row-text">{{ contract.applyAmount | formatMoney }}</div>
                    <div class="row-text row-gap">{{ contract.applyDate}}</div>
                  </div>
                </div>
                <span class="item-status">{{ contract.contractStatusName}}</span>
                <img class="item-arrow" src="../../assets/arrow-r.png" alt="">
              </li>
            </ul>
          </div>
          <div>
          </div>
        </li>
      </ul>
    </div>
    <tab-bar />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {getUserInfo, currentContractList} from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import tabBar from '@/components/tabBar.vue'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: "Done",
  components: {
    callPhone,
    tabBar,
  },
  data() {
    return {
      contractList: [],
      item:{}
    }
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  computed: {
    ...mapState(["brand", "user"]),
    videoAuthFlag() {
      if (this.user.videoAuthFlag != 'Y'  && this.brand.videoAuthEnable == 'Y' && (this.user.applyStatus == 'APPROVE' || this.user.applyStatus == 'WAIT')) {
        return true
      }
      return false
    }
  },
  watch: {
    // 视频认证
    videoAuthFlag: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$router.push('/apply/shootVideo')
          })
        }
      },
      immediate: true,
    }
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_CHECK_CODE"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await Promise.all([
        this.getUser(),
        this.getContractDetail(),
      ])
      this.$indicator.close()
      this.$NProgress.done()
    },
    async getUser() {
      await this.$axios({
        method: "POST",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code === "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
    async getContractDetail() {
      const formData = new FormData()
      formData.append('applyId', this.user.applyId)
      await this.$axios({
        method: 'post',
        url: currentContractList,
        data: formData
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.contractList = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.done {
  background: url("../../assets/header-bg.png") no-repeat;
  background-size: 100% auto;
  min-height: 500px;
  .content{
    padding-top: 50px;
    .box{
      background: linear-gradient(to bottom, #F9F6FF, #E2D6FF);
      border-radius: 20px;
      margin-top: 10px;
    }
    .box-title{
      display: flex;
      align-items: center;
      font-weight: bold;
      height: 50px;
      line-height: 50px;
      img {
        width: 30px;
        margin: 0 15px;
        border-radius: 50%;
      }
    }
  }
  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .item-ul {
    flex-grow: 1;
    position: relative;
    .item-li {
      padding: 10px 20px 20px 20px;
      text-decoration: none;
      border-bottom: 2px solid #FFFFFF;
      display: flex;
      align-items: center;
    }
    .item-status{
      font-size: 14px;
      color: #855EF4;
    }
    .item-arrow{
      margin-left: 3px;
      height: 13px;
    }
    .row-text{
      color: #666666;
    }
    .row-gap{
      margin-top: 5px;
    }
    .wrap {
      flex: 1;
      border-radius: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      height: 45px;
      color: #111111;
      font-size: 18px;
      img {
        width: 20px;
        margin: 0 15px;
      }
      div {
        color: #000;
        padding-left: 20px;
      }
    }
    .row {
      height: 30px;
      color: #A8A8A8;
      font-size: 14px;
      font-weight: bold;
      align-items: center;
    }
  }
  .title {
    text-align: center;
    padding: 10px;
    color: #4F9B41;
    font-size: 12px;
    div {
      &:nth-last-child(2) {
        margin: 10px 0 5px 0;
        font-size: 14px;
        text-align: center;
        color: #1D1D1D;
        font-weight: 600;
      }
    }
    .refresh {
      color: red;
      font-size: 13px;
    }
    .icon {
      font-size: 60px;
    }
  }
  .content {
    margin: 10px 35px;
    /deep/ .mint-cell-wrapper {
      padding: 0;
    }
    /deep/ .mint-cell-text {
      font-size: 18px;
      color: #9CA7AB;
    }
    .mint-cell {
      &:first-child {
        /deep/ .mint-cell-value {
          font-size: 13px;
          color: #9CA7AB;
        }
      }
    }
    /deep/ .mint-cell-value {
      font-size: 18px;
      color: #9CA7AB;
    }
  }
  .msg {
    margin: 20px 40px 0px 40px;
    position: relative;
    font-size: 12px;
    color: #4F9B41;
  }
  .footer {
    text-align: center;
    padding: 40px 10px 20px 10px;
    .mint-button {
      margin-bottom: 10px;
    }
  }
  .tips {
    margin: 10px;
    color: #FF4949;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
}
</style>